<template>
  <div class="w-full h-24 bg-white rounded-xl shadow-soft flex flex-row justify-between items-center p-3 text-new-black font-arial cursor-pointer">
    <div v-if="imageType === 'user'" class="w-20 h-full flex flex-row justify-center items-center bg-white flex-shrink-0">
      <div class="relative w-16 h-16 rounded-full bg-white flex flex-row justify-center items-center border-2 border-hi-lighgrey overflow-hidden">
        <div v-if="image" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + image + ')' }"></div>
        <profileSVG v-else />
      </div>
    </div>
    <div v-else class="w-20 h-full flex flex-row justify-center items-center flex-shrink-0 text-3xl p-2">
      <loadingSVG v-if="loading" class="text-new-black" />
      <circleProgress v-else-if="showCircle" class="w-full h-full" :done="done" :total="total"/>
      <editSVG v-else />
    </div>
    <div class="px-4 w-full overflow-hidden">
      <div class="truncate">{{ heading }}</div>
      <div class="text-sm truncate">{{ subheading }}</div>
      <div v-if="status !== 'Bestanden' && status !== 'Nicht bestanden'" class="px-3 rounded-lg inline-block text-sm" :style="{'background-color': currentCourseColor3, 'color': currentCourseColor}">{{ status }}</div>
      <div v-else class="px-3 rounded-lg inline-block text-sm" :class="{'bg-red-100 text-red-500' : status === 'Nicht bestanden', 'bg-green-200 text-green-700' : status === 'Bestanden'}">{{ status }}</div>
    </div>
    <chevron class="flex-shrink-0" />
  </div>
</template>

<script>
// Mixins
import colors from '../../../mixins/colors.vue';

// SVG
import chevron from '../../../assets/svg/chevron.vue';
import profileSVG from '../../../assets/svg/profile.vue';
import circleProgress from '../../../assets/svg/circleProgress.vue';
import loadingSVG from '../../../assets/svg/loading.vue';
import editSVG from '../../../assets/svg/edit.vue';


export default {
  name: 'recentItem',
  props: ['heading', 'subheading', 'status', 'image', 'imageType', 'done', 'total', 'loading', 'showCircle'],
  mixins: [colors],
  components: {
    chevron, profileSVG, circleProgress, loadingSVG, editSVG,
  },
};
</script>
